/*img {*/
/*    display: block;*/
/*}*/

.img {
    border-radius: 20px;
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.img:before {
    text-align: center;
    border-radius: 20px;
    bottom: 2px;
    /*box-shadow: inset 0 0 5px 5px #000;*/
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;*/
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    content: "";
    left: 2px;
    position: absolute;
    right: 2px;
    top: 20px;
    z-index: 1;
}